export const API_URL = "https://trip-mitra.com";

export const HTTP_API_URL = "http://trip-mitra.com";

export const HTTP_SERVER_API_URL = "http://52.66.179.110";

export const CORPORATES = {
  PERSISTENT: "persistent",
  XORIANT: "xoriant",
  ACCENTURE: "accenture",
  ACCION: "accion",
  ICERTIS: "icertis",
  BRIDGENEXT: "bridgenext",
};

export const CORPORATE_LIST = [
  "persistent",
  "xoriant",
  "accenture",
  "accion",
  "icertis",
  "bridgenext",
];
