import "./CorporateVideoComponent.scss";

import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";

import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import OwlCarousel from "react-owl-carousel";

import { useHistory, useParams } from "react-router-dom";
import corporateVideo from "../../assets/video/sample_video.mp4";
import { CORPORATES } from "../../shared/Constants";

const CorporateVideoComponent = (props) => {
  const params = useParams();
  const dispatch = useDispatch();
  const history = useHistory();

  const getVideo = () => {
    switch (params.corporateName) {
      case CORPORATES.PERSISTENT:
        return "https://prod-trip-mitra-corporate-videos.s3.ap-south-1.amazonaws.com/sample_video.mp4";
      case CORPORATES.XORIANT:
        return "https://prod-trip-mitra-corporate-videos.s3.ap-south-1.amazonaws.com/Xoriant.mp4";
      case CORPORATES.ACCENTURE:
        return "https://prod-trip-mitra-corporate-videos.s3.ap-south-1.amazonaws.com/Accenture4-video.mp4";
      case CORPORATES.ACCION:
        return "https://prod-trip-mitra-corporate-videos.s3.ap-south-1.amazonaws.com/Accion.mp4";
      case CORPORATES.BRIDGENEXT:
        return "https://prod-trip-mitra-corporate-videos.s3.ap-south-1.amazonaws.com/Bridgenext.mp4";
      case CORPORATES.ICERTIS:
        return "https://prod-trip-mitra-corporate-videos.s3.ap-south-1.amazonaws.com/Icertis.mp4";
      default:
        return "https://prod-trip-mitra-corporate-videos.s3.ap-south-1.amazonaws.com/sample_video.mp4";
    }
  };

  return (
    <div className="testimonial-component pt-2 px-2">
      <div className="dashboard_common_table">
        <div className="row mt-3">
          <div className="col-12 video-container">
            <div className="card">
              <div className="card-body card-body-custom">
                <video
                  className="video-style"
                  src={getVideo()}
                  muted
                  controls
                ></video>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CorporateVideoComponent;
